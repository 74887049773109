/* TT Norms Pro */

@font-face {
  font-family: "TT Norms Pro";
  src: url("./fonts/TTNormsPro/Bold/TTNormsPro-Bold.eot");
  src: url("./fonts/TTNormsPro/Bold/TTNormsPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/TTNormsPro/Bold/TTNormsPro-Bold.woff2") format("woff2"),
    url("./fonts/TTNormsPro/Bold/TTNormsPro-Bold.woff") format("woff"),
    url("./fonts/TTNormsPro/Bold/TTNormsPro-Bold.ttf") format("truetype"),
    url("./fonts/TTNormsPro/Bold/TTNormsPro-Bold.svg#TTNormsPro-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Norms Pro";
  src: url("./fonts/TTNormsPro/Regular/TTNormsPro-Regular.eot");
  src: url("./fonts/TTNormsPro/Regular/TTNormsPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/TTNormsPro/Regular/TTNormsPro-Regular.woff2") format("woff2"),
    url("./fonts/TTNormsPro/Regular/TTNormsPro-Regular.woff") format("woff"),
    url("./fonts/TTNormsPro/Regular/TTNormsPro-Regular.ttf") format("truetype"),
    url("./fonts/TTNormsPro/Regular/TTNormsPro-Regular.svg#TTNormsPro-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@primary-color: #025f9e;@font-family: TT Norms Pro;@font-size-base: 14px;@font-size-lg: @font-size-base + 2px;@font-size-sm: 12px;@dropdown-font-size: @font-size-sm;@btn-border-radius-base: 8px;@btn-primary-color: #fff;